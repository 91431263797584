import React from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Form,
  Modal,
  SectionItem,
} from '@jvs-group/jvs-mairistem-composants';
import { getElus, type InputData } from '@jvs-group/jvs-mairistem-comptabilite';
import {
  ClipboardInput,
  Combobox,
  Date as DateInput,
  UserRole,
  getErrorMessage,
} from '@jvs-group/jvs-mairistem-finances-utils';
import dayjs from 'dayjs';
import type Simulation from '../../interfaces/simulation/simulation';
import { updateSimulation } from '../../services/simulation/simulation';
import './SimulationParametresModal.less';

interface SimulationParametresModalProps {
  onClose: () => void;
  onSave: (data: Simulation) => void;
  open: boolean;
  simulation: Simulation;
  userRole: UserRole;
}

const OPTIONS_REUNION_SESSION = [
  {
    'data-testid': 'reunionSessionOrdinaire',
    key: 0,
    value: 'ordinaire',
    text: 'Ordinaire',
  },
  {
    'data-testid': 'reunionSessionExtraordinaire',
    key: 1,
    value: 'extraordinaire',
    text: 'Extraordinaire',
  },
];

const SimulationParametresModal = ({
  onClose,
  onSave,
  open,
  simulation,
  userRole = UserRole.NORMAL,
}: SimulationParametresModalProps) => {
  const [data, setData] = React.useState<Simulation>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingInitialization, setLoadingInitialization] = React.useState<boolean>(false);

  const handleChangeDate = (value: string, name: string) => {
    setData((old) => ({ ...old, [name]: value }));
  };

  const handleChangeInput = (e, { name, value }: InputData) => {
    setData((old) => ({ ...old, [name]: value }));
  };

  const handleInitialize = async () => {
    setLoadingInitialization(true);

    const assembleeDeliberante = `de ${simulation?.exercice?.entite?.raisonSociale}`;
    const presentateur = `${simulation?.exercice?.entite?.ordonnateur}, ${simulation?.exercice?.entite?.qualiteOrdonnateur}`;
    const lieu = simulation?.exercice?.entite?.localiteDestination;
    let nbMembresExercice = 0;

    try {
      const params = new URLSearchParams({
        afterDateFinContrat: dayjs().format('YYYY/MM/DD'),
        all: 'true',
        identifiantEntite: simulation?.exercice?.identifiantEntite?.toString(),
        isNullDateFinContrat: 'true',
      });

      const { data } = await getElus(params);
      nbMembresExercice = data.length;
    } catch (e) {
      toast.error('Erreur lors de la récupération des elus');
    }

    setData((old) => ({
      ...old,
      deliberateur: assembleeDeliberante,
      deliberationLieu: lieu,
      libelleFin: lieu,
      nbMembresExercice,
      presentateur,
      presentationLieu: lieu,
    }));

    setLoadingInitialization(false);
  };

  const handleMount = () => {
    setData(simulation);
  };

  const handleSave = async () => {
    const nbrVoteTotal = (Number(data?.nbVoteAbstention ?? 0) + Number(data?.nbVoteContre ?? 0) + Number(data?.nbVotePour ?? 0));

    if (nbrVoteTotal > data?.nbMembresExercice) {
      toast.error("Le nombre de votes n'est pas cohérent avec le nombre de suffrages exprimés");
      return;
    }

    try {
      setLoading(true);
      await updateSimulation(simulation.identifiant, data);
      onSave?.(data);
      toast.success('Paramètres sauvegardés avec succès');
    } catch (e) {
      toast.error(getErrorMessage(e, 'Erreur lors de la sauvegarde des paramètres'));
    } finally {
      setLoading(false);
    }
  };

  const isDisabled = userRole < UserRole.CHARGE_PILOTAGE;

  return (
    <Modal
      closeIcon
      data-testid="simulationParametreModal"
      onClose={onClose}
      onMount={handleMount}
      open={open}
    >
      <Modal.Header content="Paramètres de l'élaboration du budget" />
      <Modal.Content className="simulationParametresModalContent">
        <Form>
          <SectionItem compact title="Recensement des demandes d'inscription budgétaire">
            <Form.Field
              control={DateInput}
              data-testid="dateButoirDemandeInput"
              disabled={isDisabled}
              label="Date butoir de saisie des demandes par les services"
              name="dateButoirDemande"
              onChange={handleChangeDate}
              value={data?.dateButoirDemande}
            />
          </SectionItem>
          <SectionItem compact title="Informations sur la séance">
            <Form.Group>
              <Form.Field
                control={DateInput}
                data-testid="dateConvocationInput"
                disabled={isDisabled}
                fluid
                label="Convocation"
                name="dateConvocation"
                onChange={handleChangeDate}
                value={data?.dateConvocation}
                width={3}
              />
              <Form.Input
                disabled={isDisabled}
                input={{ 'data-testid': 'deliberateurInput' }}
                label="Assemblée délibérante"
                name="deliberateur"
                placeholder="de la mairie de Jolieville, du syndicat XYZ, ..."
                value={data?.deliberateur}
                width={6}
              />
              <Form.Field
                control={Combobox}
                data-testid="reunionSessionInput"
                disabled={isDisabled}
                label="Type de session"
                menuPortalTarget={document.body}
                name="reunionSession"
                onChange={handleChangeInput}
                options={OPTIONS_REUNION_SESSION}
                placeholder="ordinaire, extraordinaire"
                search
                selection
                value={data?.reunionSession}
                width={4}
              />
              <Form.Input
                disabled={isDisabled}
                input={{ 'data-testid': 'nbMembresExerciceInput' }}
                label="Membres en exercice"
                name="nbMembresExercice"
                onChange={handleChangeInput}
                type="number"
                value={data?.nbMembresExercice}
                width={3}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={DateInput}
                data-testid="datePresentationInput"
                disabled={isDisabled}
                fluid
                label="Présenté le"
                name="datePresentation"
                onChange={handleChangeDate}
                value={data?.datePresentation}
                width={3}
              />
              <Form.Field
                control={ClipboardInput}
                dataTestId="presentationLieuInput"
                disabled={isDisabled}
                label="à"
                name="presentationLieu"
                onChange={handleChangeInput}
                placeholder="Localité où est présenté le document"
                value={data?.presentationLieu}
                width={6}
              />
              <Form.Input
                disabled={isDisabled}
                input={{ 'data-testid': 'presentateurInput' }}
                label="par"
                name="presentateur"
                onChange={handleChangeInput}
                placeholder="Prénom Nom, Fonction"
                value={data?.presentateur}
                width={7}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={DateInput}
                data-testid="dateDeliberationInput"
                disabled={isDisabled}
                fluid
                label="Délibéré le"
                name="dateDeliberation"
                onChange={handleChangeDate}
                value={data?.dateDeliberation}
                width={3}
              />
              <Form.Input
                disabled={isDisabled}
                input={{ 'data-testid': 'deliberationLieuInput' }}
                label="à"
                name="deliberationLieu"
                onChange={handleChangeInput}
                placeholder="Localité où est délibérée la décision"
                value={data?.deliberationLieu}
                width={6}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                disabled={isDisabled}
                input={{ 'data-testid': 'nbMembresPresentInput' }}
                label="Membres présents"
                name="nbMembresPresent"
                onChange={handleChangeInput}
                type="number"
                value={data?.nbMembresPresent}
                width={3}
              />
              <Form.Input
                disabled={isDisabled}
                input={{ 'data-testid': 'nbSuffragesExprimeInput' }}
                label="Suffrages exprimés"
                name="nbSuffragesExprime"
                onChange={handleChangeInput}
                type="number"
                value={data?.nbSuffragesExprime}
                width={3}
              />
              <Form.Field width={5} />
              <Form.Input
                disabled={isDisabled}
                input={{ 'data-testid': 'nbVotePourInput' }}
                label="Pour"
                name="nbVotePour"
                onChange={handleChangeInput}
                type="number"
                value={data?.nbVotePour}
                width={2}
              />
              <Form.Input
                disabled={isDisabled}
                input={{ 'data-testid': 'nbVoteContreInput' }}
                label="Contre"
                name="nbVoteContre"
                onChange={handleChangeInput}
                type="number"
                value={data?.nbVoteContre}
                width={2}
              />
              <Form.Input
                disabled={isDisabled}
                input={{ 'data-testid': 'nbVoteAbstentionInput' }}
                label="Abstentions"
                name="nbVoteAbstention"
                onChange={handleChangeInput}
                type="number"
                value={data?.nbVoteAbstention}
                width={2}
              />
            </Form.Group>
          </SectionItem>
          <SectionItem compact title="Transmission au contrôle de légalité">
            <Form.Group>
              <Form.Field
                control={DateInput}
                data-testid="dateTransmissionPrefectureInput"
                disabled={isDisabled}
                fluid
                label="Transmis le"
                name="dateTransmissionPrefecture"
                onChange={handleChangeDate}
                value={data?.dateTransmissionPrefecture}
                width={3}
              />
              <Form.Field
                control={DateInput}
                data-testid="datePublicationInput"
                disabled={isDisabled}
                fluid
                label="Publié le"
                name="datePublication"
                onChange={handleChangeDate}
                value={data?.datePublication}
                width={3}
              />
              <Form.Field width={2} />
              <Form.Field
                control={DateInput}
                data-testid="dateFinInput"
                disabled={isDisabled}
                fluid
                label="le"
                name="dateFin"
                onChange={handleChangeDate}
                value={data?.dateFin}
                width={3}
              />
              <Form.Input
                disabled={isDisabled}
                input={{ 'data-testid': 'libelleFinInput' }}
                label="à"
                name="libelleFin"
                onChange={handleChangeDate}
                placeholder="Localité où est mis en place l'arrêté"
                value={data?.libelleFin}
                width={6}
              />
            </Form.Group>
          </SectionItem>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Initialiser à partir des paramètres du budget"
          data-testid="initializeButton"
          disabled={isDisabled || loadingInitialization}
          loading={loadingInitialization}
          onClick={handleInitialize}
        />
        <Button
          content="Mettre à jour"
          data-testid="saveButton"
          disabled={isDisabled || loading}
          loading={loading}
          onClick={handleSave}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

export default SimulationParametresModal;

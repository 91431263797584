import React from 'react';
import type { JVSIcons } from '@jvs-group/jvs-mairistem-composants';
import type { UserRole } from '@jvs-group/jvs-mairistem-finances-utils';
import BaseOutil from './BaseOutil';
import TypePeriode from '../../constants/simulation/typePeriode';
import type Simulation from '../../interfaces/simulation/simulation';
import NextPeriodeModal from '../../components/simulation/Outils/Periode/NextPeriodeModal';

const getPeriodeIcon = (typePeriode: TypePeriode): JVSIcons => {
  switch (typePeriode) {
    case TypePeriode.DEMANDE:
      return 'NotebookPen';
    case TypePeriode.PROPOSE:
      return 'Vote';
    case TypePeriode.VOTE:
      return 'HandCoins';
    default:
      return 'ArrowRightToLine';
  }
};

const getPeriodeTitle = (typePeriode: TypePeriode): string => {
  switch (typePeriode) {
    case TypePeriode.DEMANDE:
      return 'Formuler les propositions budgétaires';
    case TypePeriode.PROPOSE:
      return 'Procéder au vote par l’assemblée';
    case TypePeriode.VOTE:
      return 'Rendre exécutoire le budget';
    case TypePeriode.CA_CFU:
      return 'Valider les restes à réaliser';
    default:
      return '';
  }
};

class NextPeriode extends BaseOutil {
  constructor(simulation: Simulation) {
    super();
    this.code = 'NEX_PER';
    this.icon = { iconSet: 'Lucide', name: getPeriodeIcon(simulation?.typePeriode) };
    this.title = getPeriodeTitle(simulation?.typePeriode);
  }

  isEnabled(userRole: UserRole, simulation: Simulation): boolean {
    return super.isEnabled(userRole, simulation) && !simulation?.transfertCpta;
  }

  renderModal(
    simulation: Simulation,
    onClose: () => void,
    onValidate: (shouldRefresh: boolean) => void,
    open: boolean,
  ) {
    return (
      <NextPeriodeModal
        onClose={onClose}
        onValidate={onValidate}
        open={open}
        simulation={simulation}
        title={this.title}
      />
    );
  }
}

export default NextPeriode;

import React from 'react';
import type { UserRole } from '@jvs-group/jvs-mairistem-finances-utils';
import BaseOutil from './BaseOutil';
import TypeBudget from '../../constants/simulation/typeBudget';
import type Simulation from '../../interfaces/simulation/simulation';
import ControleCoherenceModal from '../../components/simulation/Outils/ControleCoherence/ControleCoherenceModal';

class ControleCoherence extends BaseOutil {
  constructor(simulation: Simulation) {
    super();
    this.code = 'CTRL_CHR';
    this.icon = {
      iconSet: 'Lucide',
      name: 'ListChecks',
    };
    this.title = simulation?.budget?.type === TypeBudget.COMPTE_ADMINISTRATIF
      ? 'Contrôler la cohérence des réalisations' : 'Contrôler la cohérence des prévisions';
  }

  isEnabled(userRole: UserRole, simulation: Simulation): boolean {
    return !simulation?.transfertCpta && super.isEnabled(userRole, simulation);
  }

  renderModal(
    simulation: Simulation,
    onClose: () => void,
    onValidate: (data: unknown) => void,
    open: boolean,
  ) {
    return (
      <ControleCoherenceModal
        onClose={onClose}
        onValidate={onValidate}
        open={open}
        simulation={simulation}
      />
    );
  }
}

export default ControleCoherence;

import React from 'react';
import { isNil } from 'lodash';
import type { UserRole } from '@jvs-group/jvs-mairistem-finances-utils';
import BaseOutil from './BaseOutil';
import type Simulation from '../../interfaces/simulation/simulation';
import TypeBudget from '../../constants/simulation/typeBudget';
import TypeReport from '../../constants/simulation/typeReport';
import RecopieReportModal from '../../components/simulation/Outils/RecopieReport/RecopieReportModal';

class RecopieReport extends BaseOutil {
  constructor(simulation: Simulation) {
    super();
    this.code = 'RECOP_REP';
    this.icon = {
      iconSet: 'Lucide',
      name: 'ClipboardPaste',
    };
    const anneeExercice = simulation?.exercice?.anneeExercice;
    this.title = `Reprendre les résultats reportés et RAR de ${!isNil(anneeExercice) ? (anneeExercice - 1) : ''}`;
  }

  isEnabled(userRole: UserRole, simulation: Simulation) {
    if (super.isEnabled(userRole, simulation) && !simulation?.transfertCpta) {
      if (simulation?.budget?.type === TypeBudget.BUDGET_PRIMITIF
        && simulation?.exercice?.reportBpBs === TypeReport.BUDGET_PRIMITIF) {
        return true;
      }

      if (simulation?.budget?.type === TypeBudget.BUDGET_SUPPLEMENTAIRE
        && simulation?.exercice?.reportBpBs === TypeReport.BUDGET_SUPPLEMENTAIRE) {
        return true;
      }

      if (simulation?.budget?.type === TypeBudget.DECISION_MODIFICATIVE
        && simulation?.exercice?.reportBpBs === TypeReport.DECISION_MODIFICATIVE) {
        return true;
      }
    }

    return false;
  }

  renderModal(
    simulation: Simulation,
    onClose: () => void,
    onValidate: (data: unknown) => void,
    open: boolean,
  ) {
    return (
      <RecopieReportModal
        onClose={onClose}
        onValidate={onValidate}
        open={open}
        simulation={simulation}
      />
    );
  }
}

export default RecopieReport;

import React from 'react';
import { uniqueId } from 'lodash';
import { getLibelleImputation } from '@jvs-group/jvs-mairistem-finances-utils';
import type { ImportData } from './ImportTable';
import ImportTable from './ImportTable';
import { importEmprunt } from '../../../../services/simulation/simulation';
import type Simulation from '../../../../interfaces/simulation/simulation';

interface ImportEmpruntProps {
  simulation: Simulation;
  onClose: () => void;
  onValidate: () => void;
}

const ImportEmprunt = ({
  simulation,
  onClose,
  onValidate,
}: ImportEmpruntProps) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [emprunts, setEmprunts] = React.useState<ImportData[]>([]);

  const handleLoadImportEmprunt = async () => {
    try {
      const emprunts = await importEmprunt(simulation?.identifiant);
      const article16 : ImportData = {
        key: '16',
        libelle: 'Remboursement du capital de la dette:',
        libelleDetail: 'dépense au compte 16',
        children: [],
        montant: 0,
        montantImputationComptable: 0,
        montantProposition: 0,
      };
      const article66 : ImportData = {
        key: '66111',
        libelle: 'Remboursement des intérêts de la dette:',
        libelleDetail: 'dépense au compte 66',
        children: [],
        montant: 0,
        montantImputationComptable: 0,
        montantProposition: 0,
      };
      const article66112 : ImportData = {
        key: 'icne',
        libelle: 'ICNE: dépense au compte 66112',
        libelleDetail: 'dépense au compte 66112',
        children: [],
        montant: 0,
        montantImputationComptable: 0,
        montantProposition: 0,
      };

      emprunts?.forEach((emprunt) => {
        const e = {
          key: uniqueId(),
          libelle: getLibelleImputation(
            emprunt.sens,
            emprunt.section,
            emprunt.chapitre,
            emprunt.article,
            emprunt?.operation,
            emprunt?.fonction,
            emprunt?.ventilation,
            emprunt?.analytique,
          ),
          montant: emprunt.montant,
          montantImputationComptable: emprunt.montantImputationComptable,
          montantProposition: emprunt.montantProposition,
          identifiantImputation: emprunt.identifiantImputation,
        };

        if (emprunt?.article?.startsWith('16')) {
          article16.children.push(e);
          article16.montant += emprunt.montant;
          article16.montantImputationComptable += emprunt.montantImputationComptable;
          article16.montantProposition += emprunt.montantProposition;
        } else if (emprunt?.article.startsWith('66') && emprunt?.article !== '66112') {
          article66.children.push(e);
          article66.montant += emprunt.montant;
          article66.montantImputationComptable += emprunt.montantImputationComptable;
          article66.montantProposition += emprunt.montantProposition;
        } else if (emprunt?.article === '66112') {
          article66112.children.push(e);
          article66112.montant += emprunt.montant;
          article66112.montantImputationComptable += emprunt.montantImputationComptable;
          article66112.montantProposition += emprunt.montantProposition;
        }
      });

      setEmprunts([
        article16,
        article66,
        article66112,
      ]);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    handleLoadImportEmprunt();
  }, []);

  return (
    <ImportTable
      simulation={simulation}
      onClose={onClose}
      onValidate={onValidate}
      loading={loading}
      data={emprunts}
    />
  );
};

export default ImportEmprunt;
